<template>
  <div>
    <device-brand />
    <!--    Ekle Modal-->
    <b-modal
      id="new_record_modal"
      ref="new_record_modal"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Yeni Kayıt Ekle"
      footer-class="d-none"
      size="md"
      @hide="close_new_record_modal"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Adı"
              label-for="name"
            >
              <b-form-input
                v-model="name"
                placeholder="Adı"
                name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="">
          <b-col cols="4">
            <b-form-group><span class="d-flex">IEC-104 <b-form-checkbox
              v-model="is_active_iec104"
              true-value="true"
              false-value="false"
              class="ml-1"
            /></span></b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group><span class="d-flex">IEC-61850 <b-form-checkbox
              v-model="is_active_iec61850"
              true-value="true"
              false-value="false"
              class="ml-1"
            /></span></b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group><span class="d-flex">MODBUS <b-form-checkbox
              v-model="is_active_modbus"
              true-value="true"
              false-value="false"
              class="ml-1"
            /></span></b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            size="sm"
            @click="new_record_add_button"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-1"
            />
            <span class="align-middle">Ekle</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Ekle Modal-->

    <!--    Güncelle Modal-->
    <b-modal
      id="update_record_modal"
      ref="update_record_modal"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Kayıt Düzenle"
      footer-class="d-none"
      size="md"
      @hide="close_edit_record_modal"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Adı"
              label-for="name"
            >
              <b-form-input
                v-model="edit_name"
                placeholder="Adı"
                name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="">
          <b-col cols="4">
            <b-form-group><span class="d-flex">IEC-104 <b-form-checkbox
              v-model="edit_is_active_iec104"
              true-value="true"
              false-value="false"
              class="ml-1"
            /></span></b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group><span class="d-flex">IEC-61850 <b-form-checkbox
              v-model="edit_is_active_iec61850"
              true-value="true"
              false-value="false"
              class="ml-1"
            /></span></b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group><span class="d-flex">MODBUS <b-form-checkbox
              v-model="edit_is_active_modbus"
              true-value="true"
              false-value="false"
              class="ml-1"
            /></span></b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            variant="success"
            size="sm"
            @click="record_edit_button()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-1"
            />
            <span class="align-middle">Güncelle</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Güncelle Modal-->

    <!--    Sil Modal-->
    <b-modal
      id="delete_record_modal"
      ref="delete_record_modal"
      class="danger"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Kayıt Sil"
      footer-class="d-none"
      size="md"
      @hide="close_delete_record_modal"
    >
      <div>
        <b-card-text class="text-center">
          <span class="text-danger">{{ selected_row_name }}</span><span class="mx-1">isimli kaydı silmek istediğinize emin misiniz?</span>
        </b-card-text>
        <b-row class="justify-content-between">
          <b-button
            variant="secondary"
            size="sm"
            @click="close_delete_record_modal()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-1"
            />
            <span class="align-middle">İptal</span>
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="record_delete_button()"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-1"
            />
            <span class="align-middle">Sil</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Sil Modal-->
    <b-row class="justify-content-center">
      <b-col cols="7">
        <b-card
          no-body
          class="p-50"
        >
          <b-row class="mb-25">

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Sayfada</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>kayıt göster</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Ara..."
                />
                <b-button
                  v-b-modal.new_record_modal
                  variant="primary"
                >
                  <span class="text-nowrap">Yeni Ekle</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-table
            style="min-height: 400px"
            responsive
            :items="table_data"
            :fields="tableColumns"
            primary-key="id"
            class="text-nowrap"
            show-empty
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            empty-text="Herhangi bir kayıt bulunamadı."
          >
            <!-- Column: is_active_iec104 -->
            <template #cell(is_active_iec104)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.is_active_iec104==true"
                  icon="CheckIcon"
                  class="text-success"
                />
                <feather-icon
                  v-if="data.item.is_active_iec104==false"
                  icon="XIcon"
                  class="text-danger"
                />
              </div>
            </template>
            <!-- Column: is_active_iec104 -->
            <!-- Column: is_active_iec61850 -->
            <template #cell(is_active_iec61850)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.is_active_iec61850==true"
                  icon="CheckIcon"
                  class="text-success"
                />
                <feather-icon
                  v-if="data.item.is_active_iec61850==false"
                  icon="XIcon"
                  class="text-danger"
                />
              </div>
            </template>
            <!-- Column: is_active_iec61850 -->
            <!-- Column: is_active_modbus -->
            <template #cell(is_active_modbus)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.is_active_modbus==true"
                  icon="CheckIcon"
                  class="text-success"
                />
                <feather-icon
                  v-if="data.item.is_active_modbus==false"
                  icon="XIcon"
                  class="text-danger"
                />
              </div>
            </template>
            <!-- Column: is_active_modbus -->
            <!-- Column: Actions -->
            <template #cell(id)="data">
              <div class="text-nowrap">
                <!-- Dropdown -->
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="rowUpdateModal(data.item.id, )">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Düzenle</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="show_device_brand_modal(data.item.id )">
                    <feather-icon icon="AwardIcon" />
                    <span class="align-middle ml-50">Marka Listesi</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="rowDeleteModal(data.item.id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Sil</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
  BModal,
  BCardHeader,
  BCardBody,
  BLink,
  BFormInput,
  BTable,
  BBadge,
  BCardText,
  BFormGroup,
  BDropdown,
  BFormCheckbox,
  BDropdownItem,
  BPagination, VBModal, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import 'vue-tel-input/dist/vue-tel-input.css'
import { VueTelInput } from 'vue-tel-input'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import DeviceBrand from '@/views/custom_app/admin/components/general_definitions/DeviceBrand.vue'

export default {
  components: {
    DeviceBrand,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BModal,
    BCardHeader,
    BCardBody,
    BLink,
    BFormInput,
    BTable,
    BFormCheckbox,
    BBadge,
    BCardText,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected_row_name: null,
      selected_row_id: null,
      perPageOptions: [25, 50, 100],
      searchQuery: null,
      sortBy: null,
      isSortDirDesc: null,
      roleOptions: [],
      table_data: null,
      tableColumns: [
        { key: 'device_type_name', label: 'Cihaz Tipi', sortable: true },
        { key: 'is_active_iec104', label: 'IEC-104', sortable: true },
        { key: 'is_active_iec61850', label: 'IEC-61850', sortable: true },
        { key: 'is_active_modbus', label: 'Modbus', sortable: true },
        { key: 'id', label: '#' },
      ],
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      name: null,
      is_active_iec104: false,
      is_active_iec61850: false,
      is_active_modbus: false,
      edit_name: null,
      edit_is_active_iec104: false,
      edit_is_active_iec61850: false,
      edit_is_active_modbus: false,
    }
  },
  computed: {
  },
  watch: {
    sortBy: { handler() { this.getTableData() } },
    isSortDirDesc: { handler() { this.getTableData() } },
    currentPage: {
      handler() {
        this.getTableData()
      },
    },
    searchQuery: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    this.getTableData()
  },
  setup() {

  },
  methods: {
    getTableData() {
      useJwt.getDeviceTypeList({
        // center_type: this.filter_center_type.value,
        page: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    show_device_brand_modal(row_id) {
      window.viewModal(row_id)
    },
    rowUpdateModal(row_id) {
      useJwt.deviceTypeDetail(row_id)
        .then(response => {
          this.edit_name = response.data.device_type_name
          this.edit_is_active_iec104 = response.data.is_active_iec104
          this.edit_is_active_iec61850 = response.data.is_active_iec61850
          this.edit_is_active_modbus = response.data.is_active_modbus
          this.selected_row_id = response.data.id
          this.$refs.update_record_modal.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowDeleteModal(row_id) {
      useJwt.deviceTypeDetail(row_id)
        .then(response => {
          this.selected_row_name = response.data.device_type_name
          this.selected_row_id = response.data.id
          this.$refs.delete_record_modal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    new_record_add_button() {
      const formData = new FormData()
      formData.append('device_type_name', this.name)
      formData.append('is_active_iec104', this.is_active_iec104)
      formData.append('is_active_iec61850', this.is_active_iec61850)
      formData.append('is_active_modbus', this.is_active_modbus)

      useJwt.createDeviceType(formData)
        .then(response => {
          this.getTableData()
          this.close_new_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Eklendi',
              icon: 'SaveIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Hata',
              icon: 'AlertIcon',
              variant: 'danger',
              text: error.response.data.detail,
            },
          })
          console.log(error.response)
        })
    },
    record_edit_button() {
      const formData = new FormData()
      formData.append('device_type_name', this.edit_name)
      formData.append('is_active_iec104', this.edit_is_active_iec104)
      formData.append('is_active_iec61850', this.edit_is_active_iec61850)
      formData.append('is_active_modbus', this.edit_is_active_modbus)
      useJwt.updateDeviceType(this.selected_row_id, formData)
        .then(response => {
          this.getTableData()
          this.close_edit_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Güncellendi',
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    record_delete_button() {
      useJwt.deviceTypeDelete(this.selected_row_id)
        .then(response => {
          this.getTableData()
          this.close_delete_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Silindi',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    close_new_record_modal() {
      this.$refs.new_record_modal.hide()
      this.name = null
      this.is_active_iec104 = false
      this.is_active_iec61850 = false
      this.is_active_modbus = false
    },
    close_edit_record_modal() {
      this.$refs.update_record_modal.hide()
      this.selected_row_id = null
      this.edit_is_active_iec104 = false
      this.edit_is_active_iec61850 = false
      this.edit_is_active_modbus = false
    },
    close_delete_record_modal() {
      this.$refs.delete_record_modal.hide()
      this.selected_row_id = null
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
