<template>
  <div>
    <device-brand-files />
    <device-brand-register-template />
    <!--    Ekle Modal-->
    <b-modal
      id="new_brand_record_modal"
      ref="new_brand_record_modal"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Yeni Kayıt Ekle"
      footer-class="d-none"
      size="md"
      @hide="close_new_brand_record_modal"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Adı"
              label-for="name"
            >
              <b-form-input
                v-model="name"
                placeholder="Adı"
                name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            size="sm"
            @click="new_record_add_button"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-1"
            />
            <span class="align-middle">Ekle</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Ekle Modal-->

    <!--    Güncelle Modal-->
    <b-modal
      id="update_brand_record_modal"
      ref="update_brand_record_modal"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Kayıt Düzenle"
      footer-class="d-none"
      size="lg"
      @hide="close_edit_record_modal"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Adı"
              label-for="name"
            >
              <b-form-input
                v-model="edit_name"
                placeholder="Adı"
                name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-success"
            class="mr-1"
            @click="record_edit_button()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-1"
            />
            <span class="align-middle">Güncelle</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Güncelle Modal-->

    <!--    Sil Modal-->
    <b-modal
      id="delete_brand_record_modal"
      ref="delete_brand_record_modal"
      class="danger"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Kayıt Sil"
      footer-class="d-none"
      size="lg"
      @hide="close_delete_brand_record_modal"
    >
      <div>
        <b-card-text class="text-center">
          <span class="text-danger">{{ selected_row_name }}</span><span class="mx-1">isimli kaydı silmek istediğinize emin misiniz?</span>
        </b-card-text>
        <b-row class="justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-success"
            class="mr-1"
            @click="close_delete_brand_record_modal()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-1"
            />
            <span class="align-middle">İptal</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-danger"
            class="mr-1"
            @click="record_delete_button()"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-1"
            />
            <span class="align-middle">Sil</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Sil Modal-->

    <!--    View Modal-->
    <b-modal
      id="view_record_modal"
      ref="view_record_modal"
      centered
      no-close-on-backdrop
      content-class="shadow"
      title="Marka Listesi"
      footer-class="d-none"
      size="lg"
      @hide="close_view_record_modal"
    >
      <div>
        <b-row class="mb-25">

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Sayfada</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>kayıt göster</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Ara..."
              />
              <b-button
                v-b-modal.new_brand_record_modal
                variant="primary"
              >
                <span class="text-nowrap">Yeni Ekle</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-table
          responsive
          striped
          style="min-height: 400px"
          :items="table_data"
          :fields="tableColumns"
          primary-key="id"
          class="text-nowrap"
          show-empty
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          empty-text="Herhangi bir kayıt bulunamadı."
        >
          <!-- Column: Actions -->
          <template #cell(id)="data">
            <div class="text-nowrap">
              <!-- Dropdown -->
              <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="rowUpdateModal(data.item.id, )">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Düzenle</span>
                </b-dropdown-item>
                <b-dropdown-item @click="show_device_brand_file_modal(data.item.id )">
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Dosyalar</span>
                </b-dropdown-item>
                <b-dropdown-item @click="show_device_brand_register_template_modal(data.item.id )">
                  <feather-icon icon="LayersIcon" />
                  <span class="align-middle ml-50">Register Şablonu</span>
                </b-dropdown-item>
                <b-dropdown-item @click="rowDeleteModal(data.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Sil</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <div>
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>

    </b-modal>
    <!--    View Modal-->
  </div>

</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
  BModal,
  BCardHeader,
  BCardBody,
  BLink,
  BFormInput,
  BTable,
  BBadge,
  BCardText,
  BFormGroup,
  BDropdown,
  BFormCheckbox,
  BDropdownItem,
  BPagination, VBModal, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import 'vue-tel-input/dist/vue-tel-input.css'
import { VueTelInput } from 'vue-tel-input'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import DeviceBrandFiles from '@/views/custom_app/admin/components/general_definitions/DeviceBrandFiles.vue'
import DeviceBrandRegisterTemplate
from '@/views/custom_app/admin/components/general_definitions/DeviceBrandRegisterTemplate.vue'

export default {
  components: {
    DeviceBrandFiles,
    DeviceBrandRegisterTemplate,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BModal,
    BCardHeader,
    BCardBody,
    BLink,
    BFormInput,
    BTable,
    BFormCheckbox,
    BBadge,
    BCardText,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    VueTelInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected_device_id: null,
      selected_row_name: null,
      selected_row_id: null,
      perPageOptions: [25, 50, 100],
      searchQuery: null,
      sortBy: null,
      isSortDirDesc: null,
      roleOptions: [],
      table_data: null,
      tableColumns: [
        { key: 'device_brand', label: 'Cihaz Markası', sortable: true },
        { key: 'id', label: '#' },
      ],
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      name: null,
      edit_name: null,
    }
  },
  computed: {
  },
  watch: {
    sortBy: { handler() { this.getTableData(this.selected_device_id) } },
    isSortDirDesc: { handler() { this.getTableData(this.selected_device_id) } },
    currentPage: {
      handler() {
        this.getTableData(this.selected_device_id)
      },
    },
    searchQuery: {
      handler() {
        this.getTableData(this.selected_device_id)
      },
    },
  },
  created() {
    window.viewModal = this.getTableData
  },
  setup() {

  },
  methods: {
    getTableData(device_id) {
      this.selected_device_id = device_id
      useJwt.getDeviceBrandList({
        // center_type: this.filter_center_type.value,
        page: this.currentPage,
        device_type: device_id,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
          this.$refs.view_record_modal.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowUpdateModal(row_id) {
      useJwt.deviceBrandDetail(row_id)
        .then(response => {
          this.edit_name = response.data.device_brand
          this.selected_row_id = response.data.id
          this.$refs.update_brand_record_modal.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowDeleteModal(row_id) {
      useJwt.deviceBrandDetail(row_id)
        .then(response => {
          this.selected_row_name = response.data.device_brand
          this.selected_row_id = response.data.id
          this.$refs.delete_brand_record_modal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    show_device_brand_file_modal(row_id) {
      window.viewFileModal(row_id)
    },
    show_device_brand_register_template_modal(row_id) {
      window.viewRegisterTemplateModal(row_id)
    },
    new_record_add_button() {
      const formData = new FormData()
      formData.append('device_brand', this.name)
      formData.append('device_type', this.selected_device_id)

      useJwt.createDeviceBrand(formData)
        .then(response => {
          this.getTableData(this.selected_device_id)
          this.close_new_brand_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Eklendi',
              icon: 'SaveIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Hata',
              icon: 'AlertIcon',
              variant: 'danger',
              text: error.response.data.detail,
            },
          })
          console.log(error.response)
        })
    },
    record_edit_button() {
      const formData = new FormData()
      formData.append('device_brand', this.edit_name)
      useJwt.updateDeviceBrand(this.selected_row_id, formData)
        .then(response => {
          this.getTableData(this.selected_device_id)
          this.close_edit_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Güncellendi',
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    record_delete_button() {
      useJwt.deviceBrandDelete(this.selected_row_id)
        .then(response => {
          this.getTableData(this.selected_device_id)
          this.close_delete_brand_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Silindi',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    close_new_brand_record_modal() {
      this.$refs.new_brand_record_modal.hide()
      this.name = null
    },
    close_view_record_modal() {
      this.$refs.view_record_modal.hide()
    },
    close_edit_record_modal() {
      this.$refs.update_brand_record_modal.hide()
      this.selected_row_id = null
    },
    close_delete_brand_record_modal() {
      this.$refs.delete_brand_record_modal.hide()
      this.selected_row_id = null
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
